export const environment = {
    production: true,
    banorte: false,
    ente: "H. Ayuntamiento de El Rosario",
    administracion: "2024 - 2027",
    url_escudo: "../assets/elrosario/escudo.png",
    url_administracion: "../assets/elrosario/logo-rosario-footer.png",
    url_footer: "../assets/elrosario/logo_administracion.png",
    direcciones: [
        "Palacio Municipal S/N, Centro",
        "C.P. 82800",
        "Rosario, Sinaloa"
    ],
    telefonos: [
        "(694) 952 6544"
    ],
    emails: [
        "presidencia@rosario.gob.mx"
    ],
    url_aviso: "http://elrosario.gob.mx/cms/informacion-historica/",
    url_webapi: "https://servidorprogramacion.ddns.net/PAGOS_SADMUN_EL_ROSARIO/api/",
    url_sitio: "https://pagoselrosario.com.mx/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/',
    cve_municipio: "013",
    municipio: "EL ROSARIO",
    firebase: {
        apiKey: "AIzaSyBqJpiH1JGTfyhnProzIVHFInlDAssHULY",
        authDomain: "pagoselrosario.firebaseapp.com",
        projectId: "pagoselrosario",
        storageBucket: "pagoselrosario.appspot.com",
        messagingSenderId: "640789395509",
        appId: "1:640789395509:web:d7795e86b869db8b01d97c",
        measurementId: "G-H22HB6XPLF"
    },
    leyenda_fundamento: "Ley de ingresos del municipio de El Rosario para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    id_express: "1234",
    llave: "-v0aeT5E1AtAelqxsjcG",
    img_ayuda_1: '../assets/elrosario/pasos.png',
    img_ayuda_2: '../assets/elrosario/ubicarclavecatastral.png',
    img_ayuda_3: '../assets/elrosario/consultaadeudo.png'
};